<template>
  <div class="view content-padding">
    <TableOverview
      kind="affiliate"
      :page-title="labels.affiliate_links_overview"
      :searchable="true"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getListMt"
      :items="items"
    />

    <router-view
      name="create_affiliate_link"
      modal-id="create-affiliate-link"
      kind="affiliate"
      :route-back="{ name: 'affiliate-links-overview' }"
      :title="labels.create_new_affiliate_link"
      :title-placeholder="labels.title"
      :text-placeholder="labels.description"
      :required-fields="['title', 'text']"
      :required-data-set="['title', 'description']"
      @entity-created="affiliateCreated"
    />
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";

import { mapActions, mapMutations, mapGetters } from "vuex";
const colors = require("/colors.config.json");
export default {
  name: "Overview",
  components: {
    TableOverview,
  },
  data(){
    return {
      items: {}
    }
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    footerButtons() {
      return [
        {
          label: this.labels.create_new,
          variant: "btn-purple",
          action: () => {
            if (this.$route.name !== "create-affiliate-link") {
              this.$router.push({
                name: "create-affiliate-link",
                query: { page: this.$route.query.page },
              });
            }
          },
        },
      ];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.name,
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 30%",
            whiteSpace: "nowrap",
          },
          // {
          // 	headerLabel: 'Status',
          // 	headerColor: `${colors.gray_text}`,
          // 	key: 'status',
          // 	computeFn: input => input,
          // 	bodyColor: `${colors.gray_text_2}`,
          // 	columnWidth: '1 1 55%',
          //  textAlign: 'center',
          // },
        ],
        sortable: false,
        // sortStyles: { flex: '0 0 5%', color: `${colors.gray_text}` },
        minTableWidth: "300px",
        actionsWidth: "0 0 20px",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.open,
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (data) =>
              this.$router.push({
                name: "affiliate-link",
                params: { id: data._id },
              }),
          },
        ],
      };
    },
  },
  methods: {
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("affiliate", {
      getList: "getList",
    }),
    affiliateCreated() {
      this.getListMt(this.$route.query.page);
    },
    getListMt(page) {
      let data = { page };
      if (!this.$route.query.page) data.page = 1;
      else if (!page) data.page = this.$route.query.page;

      this.getList(data)
        .then((res) => {
          if (res.success) {
            this.setTablePagination(res.data.pages);
            this.items = res.data.items
            if (
              res.data.pages &&
              Number(this.$route.query.page) !== Number(res.data.pages.current)
            ) {
              this.$router.push({ query: { page: res.data.pages.current } });
            }
          } else if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getListMt();
  },
};
</script>

<style lang="scss" scoped></style>
